/**
 *   use this to edit the js file then use the follwoing link to min th ecode for the actual inc js page
 *
 *   http://jscompress.com/
 * 
 */

 var premierCookie = function(){
	
	this.data = {
		bdy 		: 	$('body'), // where to display the message
		outerCont	: 	null,
		innerCont	: 	null,
		btn			: 	null,
		msg 		: 	'<div class="cookies-wrapper"><div class="cookies-message">We use cookies to ensure that we give you the best experience on our website. If you continue we\'ll assume that you are happy with this, or find out how to manage <a href="/cookies-and-privacy">cookies here</a>.</div><div class="button-wrapper"><button id="close-button">&times;</button></div></div>'
	}
	
	this.init();

};

var o,
	timer,
	exp = new Date();

premierCookie.prototype.init = function(){
	o 	= 	this.data;
 	//-- call and display the message 
	setAllowCookie();
};

	//-- private functions
var displayMessage = function(){
	o.bdy.prepend(o.msg);
	o.outerCont = $('.cookies-wrapper');
	o.innerCont = $('.cookies-message');
	o.btn = $('#close-button');
	o.outerCont.fadeIn(function(){
		timer  = setTimeout(removeMessage,6000);
		o.btn.on('click',function(e){ 
			e.preventDefault();
			removeMessage();
		});
	});
};

var removeMessage = function(){
	clearTimeout(timer);
	o.outerCont = $('.cookies-wrapper').fadeOut(500, function(e){$(this).remove;});
}

var setAllowCookie	=	function(){
	var expDays = 30; // number of days the cookie should last
	exp.setTime(exp.getTime() + (expDays*24*60*60*1000));
	var allowCookies  = GetCookie('allowCookies');
		  if (allowCookies == null) { 
		  	SetCookie('allowCookies', guidGenerator(), exp); 
		  	displayMessage();
		}
		
};


	var GetCookie = function(name) {

		  var arg = name + "=";

		  var alen = arg.length;

		  var clen = document.cookie.length;

		  var i = 0;

		  while (i < clen) {
			var j = i + alen;
			if (document.cookie.substring(i, j) == arg)
			return getCookieVal (j);
			i = document.cookie.indexOf(" ", i) + 1;
			if (i == 0) break;
		  }
		  return null;

		}

		

	var SetCookie = function(name, value) {
		  var argv = SetCookie.arguments;
		  var argc = SetCookie.arguments.length;
		  var expires = (argc > 2) ? argv[2] : null;
		  var path = (argc > 3) ? argv[3] : null;
		  var domain = (argc > 4) ? argv[4] : null;
		  var secure = (argc > 5) ? argv[5] : false;
		  document.cookie = name + "=" + escape (value) +
			((expires == null) ? "" : ("; expires=" + expires.toGMTString())) +
			((path == null) ? "" : ("; path=" + path)) +
			((domain == null) ? "" : ("; domain=" + domain)) +
			((secure == true) ? "; secure" : "");

		}

		

	var DeleteCookie = function(name) {
		  var exp = new Date();
		  exp.setTime (exp.getTime() - 1);
		  var cval = GetCookie (name);
		  document.cookie = name + "=" + cval + "; expires=" + exp.toGMTString();

		}

		
	var getCookieVal = function(offset) {
		  var endstr = document.cookie.indexOf (";", offset);
		  if (endstr == -1)
		  endstr = document.cookie.length;
		  return unescape(document.cookie.substring(offset, endstr));
		}
	
	var guidGenerator = function() {
		var S4 = function() {
		   return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
		};
		return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
	}